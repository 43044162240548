@font-face {
  font-family: 'Phosphate';
  src: local('Phosphate'), url(./fonts/PhosphateInline.ttf);
}

@font-face {
  font-family: 'Phosphate-Solid';
  src: local('PhosphateSolid'), url(./fonts/PhosphateRR-Solid.ttf);
}

@font-face {
  font-family: 'Phosphate-Solid-Button';
  src: local('PhosphateSolidButton'), url(./fonts/PhosphateSolid.ttf);
}

@font-face {
  font-family: 'Lineto-circular-book';
  src: local('Lineto'), url(./fonts/lineto-circular-pro-book.ttf);
}

.inner-yellow-gradient {
  background: linear-gradient(rgb(220, 220, 160), rgb(229, 229, 166));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

section .section-content {
  font-family: 'Lineto-circular-book';
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 12.5%;
  padding-right: 12.5%;
}

.flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.flex-column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
}

.flex-double-column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
}

.MuiTypography-h1,
.MuiTypography-h2,
.MuiTypography-h3,
.MuiTypography-h4,
.MuiTypography-h5 {
  text-transform: uppercase;
}

.MuiTypography-root.h1 {
  font-size: 3em;
}

.MuiTypography-root.h5 {
  font-size: 3em;
}

.text-block {
  text-align: center;
}

.sign-up-button {
  margin-left: 0;
}

#outlined-basic-helper-text {
  color: #fff;
}

.social-logo {
  background-size: contain;
  background-repeat: no-repeat;
  width: 50px;
  height: 50px;
  padding: 0px;
  display: inline-block;
}

@media screen and (max-width: 800px) {
  .social-logo-container {
    margin-left: 2em;
  }

  .social-logo {
    margin-right: 2em;
  }

  .cat-card {
    padding-top: 0.5em;
  }

  .cat-card .cat-image {
    width: 100%;
    max-height: 100%;
  }

  .cat-card .cat-card-title {
    display: none;
  }

  section .section-content.text-only,
  section .section-content.mobile-wide {
    padding-left: 0%;
    padding-right: 0%;
  }

  .flex-column {
    padding-bottom: 1em;
  }
}

.bullet-margin-image {
  display: none;
}

.bullet-margin-image img {
  transform: rotate(45deg);
}

/* DESKTOP */
@media screen and (min-width: 800px) {
  .bullet-margin-image {
    display: block;
  }

  .bullet-content {
    padding-left: '2em';
  }

  .mint-details-column {
    padding-left: 40px;
    padding-right: 40px;
  }

  .cat-cards-wrapper {
    padding-left: 10%;
    padding-right: 10%;
  }

  .cat-card {
    padding-top: 0;
    border: 8px solid #d49b2c;
    margin-top: 3em;
  }

  .cat-card .cat-card-title {
    display: block;
  }

  .cat-card .cat-image {
    max-height: 300px;
    max-width: 100%;
  }

  .flex-column {
    flex: 1;
  }

  .flex-double-column {
    flex: 2;
  }

  section .section-content {
    padding-top: 40px;
    padding-bottom: 20px;
    padding-left: 10%;
    padding-right: 10%;
  }

  section .section-content.text-only {
    padding-left: 20%;
    padding-right: 20%;
  }

  .MuiTypography-root.h1 {
    font-size: 5em;
  }

  .MuiTypography-root.h5 {
    font-size: 3.5em;
  }

  .text-block {
    text-align: left;
  }

  .sign-up-button {
    margin-left: 1em;
  }
}
